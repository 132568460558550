import { Container, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import PartService from "../services/PartService";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AddEditPart = () => {
  const { t } = useTranslation();
  const { control, register, handleSubmit, setValue, reset } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { bikeId, partId } = useParams();
  setValue("bikeId", bikeId);

  const currentYear = new Date().getFullYear();
  const yearOptions = [...Array(100).keys()].map(i => {
    const year = (currentYear - i);
    return { "value": year, "label": year.toString() }
  });

  const partTypes = [
    { value: "frame", bikeSection: "frameset", label: t("frame") },
    { value: "fork", bikeSection: "frameset", label: t("fork") },

    { value: "rear shock", bikeSection: "suspension", label: t("rear shock") },
    { value: "suspension fork", bikeSection: "suspension", label: t("suspension fork") },

    { value: "stem", bikeSection: "cocpit", label: t("stem") },
    { value: "grips", bikeSection: "cocpit", label: t("grips") },
    { value: "barends", bikeSection: "cocpit", label: t("barends") },
    { value: "handlebar", bikeSection: "cocpit", label: t("handlebar") },
    { value: "handlebar tape", bikeSection: "cocpit", label: t("handlebar tape") },
    { value: "saddle", bikeSection: "cocpit", label: t("saddle") },
    { value: "seatpost", bikeSection: "cocpit", label: t("seatpost") },
    { value: "display", bikeSection: "cocpit", label: t("display") },
    { value: "headset", bikeSection: "cocpit", label: t("headset") },

    { value: "brake", bikeSection: "brakes", label: t("brake") },
    { value: "brake lever", bikeSection: "brakes", label: t("brake lever") },

    { value: "rear derailleur", bikeSection: "drivetrain", label: t("rear derailleur") },
    { value: "front derailleur", bikeSection: "drivetrain", label: t("front derailleur") },
    { value: "gear shifter", bikeSection: "drivetrain", label: t("gear shifter") },
    { value: "cassette", bikeSection: "drivetrain", label: t("cassette") },
    { value: "sprocket", bikeSection: "drivetrain", label: t("sprocket") },
    { value: "chain guide", bikeSection: "drivetrain", label: t("chain guide") },
    { value: "chainring", bikeSection: "drivetrain", label: t("chainring") },
    { value: "chain", bikeSection: "drivetrain", label: t("chain") },
    { value: "crankset", bikeSection: "drivetrain", label: t("crankset") },
    { value: "bottom bracket", bikeSection: "drivetrain", label: t("bottom bracket") },
    { value: "bashguard", bikeSection: "drivetrain", label: t("bashguard") },
    { value: "pedals", bikeSection: "drivetrain", label: t("pedals") },
    { value: "drive unit", bikeSection: "drivetrain", label: t("drive unit") },
    { value: "power pack", bikeSection: "drivetrain", label: t("power pack") },

    { value: "rim", bikeSection: "wheels", label: t("rim") },
    { value: "tube", bikeSection: "wheels", label: t("tube") },
    { value: "tyre", bikeSection: "wheels", label: t("tyre") },
    { value: "wheel", bikeSection: "wheels", label: t("wheel") },
    { value: "hub", bikeSection: "wheels", label: t("hub") },
    { value: "spokes", bikeSection: "wheels", label: t("spokes") },

    { value: "cable", bikeSection: "other parts", label: t("cable") },
    { value: "charger", bikeSection: "other parts", label: t("charger") },
    { value: "hydration system", bikeSection: "other parts", label: t("hydration system") },
    { value: "lighting", bikeSection: "other parts", label: t("lighting") },


  ]

  const bikeSections = [
    { value: "frameset", label: t("frameset") },
    { value: "suspension", label: t("suspension") },
    { value: "cocpit", label: t("cocpit") },
    { value: "brakes", label: t("brakes") },
    { value: "drivetrain", label: t("drivetrain") },
    { value: "wheels", label: t("wheels") },
    { value: "accessories", label: t("accessories") },
    { value: "other parts", label: t("other_parts") },
  ]

  const genericErrorMessage = t("error_message.try_later");

  useEffect(() => {
    if (partId) {
      PartService.getBikePartInfo(bikeId, partId)
        .then((response) => {
          reset(response.data);
          setValue("partType", response.data.type);
          // setAlert(true);
        })
        .catch((err) => {
          if (err === 400) {
            setError(t("error_message.bad_request"))
          } else if (err === 401) {
            setError(t("error_message.unauthorized"))
          } else {
            setError(genericErrorMessage)
          }
        })
    }
  }, [bikeId, partId])

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setError("");

    const genericErrorMessage = t("error_message.try_later");

    if (!partId)
    PartService.addNewPart(data)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setError(t("error_message.bad_request"));
          if (err.response.data.message === "no_such_bike") setError(t("error_message.no_such_bike"));
        } else if (err.response.status === 401) {
          setError(t("error_message.unauthorized"))
        } else {
          setError(genericErrorMessage)
        }
      })
      .finally(() => setIsSubmitting(false));

      if (partId)
      PartService.updateBikePart(data)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setError(t("error_message.bad_request"));
          if (err.response.data.message === "no_such_bike") setError(t("error_message.no_such_bike"));
        } else if (err.response.status === 401) {
          setError(t("error_message.unauthorized"))
        } else {
          setError(genericErrorMessage)
        }
      })
      .finally(() => setIsSubmitting(false));

  }

  return (
    <Container className="my-3 col-lg-6">
      {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-1" controlId="partType">
          <Form.Label >{t("part_type")}</Form.Label>
          <Controller
            name="partType"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={partTypes.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => {
                  setValue("bikeSection", selectedOption.bikeSection);
                  onChange(selectedOption.value);
                }}
                options={partTypes}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="bikeSection">
          <Form.Label >{t("bike_section")}</Form.Label>
          <Controller
            name="bikeSection"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={bikeSections.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={bikeSections}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="manufacturer">
          <Form.Label >{t("part_manufacturer")}</Form.Label>
          <Form.Control {...register("manufacturer")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="model">
          <Form.Label >{t("part_model")}</Form.Label>
          <Form.Control {...register("model")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="year">
          <Form.Label >{t("part_year")}</Form.Label>
          <Controller
            name="year"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={yearOptions.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={yearOptions}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="serialNumber">
          <Form.Label >{t("part_serial_number")}</Form.Label>
          <Form.Control {...register("serialNumber")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="description">
          <Form.Label >{t("part_description")}</Form.Label>
          <Form.Control as="textarea" {...register("description")} />
        </Form.Group>

        <Button variant="primary" type="submit">
          {t("save_changes")}
        </Button>
      </Form >
    </Container>
  );
}

export default AddEditPart;