import $api from "../http/http";

export default class PartService {
    static async addNewPart(partData) {
        return $api.post('parts/add', {
            "partType": partData.partType,
            "bikeSection": partData.bikeSection,
            "manufacturer": partData.manufacturer,
            "model": partData.model,
            "year": partData.year,
            "serialNumber": partData.serialNumber,
            "description": partData.description,
            "bikeId": partData.bikeId
        });
    }

    static async updateBikePart(partData) {
        return $api.post('parts/updatePart', {
            "partId": partData.id,
            "partType": partData.partType,
            "bikeSection": partData.bikeSection,
            "manufacturer": partData.manufacturer,
            "model": partData.model,
            "year": partData.year,
            "serialNumber": partData.serialNumber,
            "description": partData.description,
            "bikeId": partData.bikeId
        });
    }

    static async getBikeParts(bikeId) {
        return $api.get('parts/getBikeParts', { "params": { "bikeId": bikeId } });
    }

    static async getBikeSpecs(bikeId) {
        return $api.get('parts/getBikeSpecs', { "params": { "bikeId": bikeId } });
    }

    static async getBikePartInfo(bikeId, partId) {
        return $api.get('parts/getBikePartInfo', { "params": { "bikeId": bikeId, "partId": partId } });
    }

    static async deletePart(bikeId, partId) {
        return $api.post('parts/deletePart', {
            "bikeId": bikeId,
            "partId": partId
        });
    }

    // static async getCurrentUserParts() {
    //     return $api.get('bikes/getMyParts');
    // }

    // static async getPart(bikeId) {
    //     return $api.get('bikes/getPart', { "params": { "bikeId": bikeId } });
    // }

    // static async getPartByLink(linkId) {
    //     return $api.get('bikes/getPartByLink', { "params": { "linkId": linkId } });
    // }

    // static async updatePart(bikeData) {
    //     return $api.post('bikes/update', {
    //         "id": bikeData.id,
    //         "bikeType": bikeData.bikeType,
    //         "serialNumber": bikeData.serialNumber,
    //         "manufacturer": bikeData.manufacturer,
    //         "model": bikeData.model,
    //         "year": bikeData.year,
    //         "primaryColors": bikeData.primaryColors,
    //         "description": bikeData.description,
    //         "frameSize": bikeData.frameSize,
    //         "frameMaterial": bikeData.frameMaterial,
    //         "wheelsSize": bikeData.wheelsSize,
    //         "location": bikeData.location,
    //     });
    // }

    // static async uploadPhotos(bikeId, data) {
    //     const formData = new FormData();
    //     formData.append('bikeId', bikeId);
    //     for (let i = 0; i <= data.bikePhotos.length; i++)
    //         formData.append('bikePhotos', data.bikePhotos[i]);
    //     formData.append('snPhoto', data.snPhoto[0]);
    //     for (let i = 0; i <= data.bikeWithOwnerPhotos.length; i++)
    //         formData.append('bikeWithOwnerPhotos', data.bikeWithOwnerPhotos[i]);

    //     return $api.post('bikes/uploadFile', formData, {
    //         "Content-Type": "multipart/form-data"
    //     });
    // }

    // static async deletePhoto(bikeId, photoType, photoIndex) {
    //     return $api.post('bikes/deletePartPhoto', {
    //         "bikeId": bikeId,
    //         "photoType": photoType,
    //         "photoIndex": photoIndex,
    //     });
    // }

}

