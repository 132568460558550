import { Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BsGearWide } from "react-icons/bs";
import { NavLink, useParams } from "react-router-dom";
import PartService from "../services/PartService";
import { useState, useEffect } from "react";
import { BsPencil, BsTrash } from "react-icons/bs";


const BikeParts = () => {
  const { t } = useTranslation();

  const { bikeId } = useParams();

  const [error, setError] = useState("");
  const [groupedParts, setGroupedParts] = useState();

  const genericErrorMessage = t("error_message.try_later");


  useEffect(() => {
    if (!groupedParts) {
      PartService.getBikeParts(bikeId)
        .then((response) => {
          const parts = response.data.reduce((r, a) => {
            r[a.bikeSection] = [...r[a.bikeSection] || [], a];
            return r;
          }, {});
          setGroupedParts(parts);
          // setAlert(true);
        })
        .catch((err) => {
          if (err === 400) {
            setError(t("error_message.bad_request"))
          } else if (err === 401) {
            setError(t("error_message.unauthorized"))
          } else {
            setError(genericErrorMessage)
          }
        })
    }
  }, [])

  if (!groupedParts) return null;

  const removePart = async (section, index) => {
    await PartService.deletePart(bikeId, groupedParts[section][index].id);

    let parts = { ...groupedParts };
    parts[section].splice(index, 1);
    return setGroupedParts(parts);
  }

  return (
    <>
      <Button as={NavLink} to="add" variant="primary"><BsGearWide/>{' '}{t("add_component")}</Button>
  
      {Object.keys(groupedParts).map((key) => {
        return <Row key={key} style={{ borderTop: '1px solid' }} className="my-3 pt-3">
          <Col md={3}>
            <h5>{t(key)}</h5>
          </Col>
          <Col>
            {groupedParts[key].map((item, ind) => {
              return <Row key={item.id}>
                <Col className="lh-sm">
                  <b className="fw-normal">{t(item.type)}</b><br />
                  <p className="fw-light">
                    {item.manufacturer + " " + item.model}{item.year && " " + item.year}{" " + item.description}
                  </p>
                </Col>
                <Col sm={4} lg={3}>
                  <Button variant="outline-secondary" as={NavLink} to={item.id + "/edit"}><BsPencil /></Button>{' '}
                  <Button variant="outline-danger" ><BsTrash onClick={() => removePart(key, ind)} /></Button>
                </Col>
              </Row>
            })}
          </Col>
        </Row>
      })}

    </>
  );
}

export default BikeParts;