import React, { createContext } from 'react';
import Store from "../store/store"


const store = new Store();

const StoreContext = createContext({ store, })

const StoreProvider = props => {
  return (
    <StoreContext.Provider value={{ store }}>
      {props.children}
    </StoreContext.Provider>
  )
}

export { StoreContext, StoreProvider }
