import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { StoreContext } from "../context/StoreContext";
import { useContext } from "react";
import { observer } from "mobx-react-lite";


function BikeEditMenu() {
  const { t } = useTranslation();
  const { store } = useContext(StoreContext);
  const { bikeId } = useParams();

  const currentBike = store.bikes.find(bike => bike.id === bikeId);
  // const [currentBike, setCurrentBike] = useState(store.bikes.find(bike => bike.id === bikeId));


  const menu_items = [
    "details",
    // "location",
    "photos",
    "parts",
    // "service",
    // "stickers",
    // "incidents",
  ]

  return (
    <Container>
      <Navbar className="justify-content-center">
        <h4>
          {t("edit_bike") + " " + currentBike.manufacturer + " " + currentBike.model + " "}
          {!currentBike.year ? "": currentBike.year}
        </h4>
      </Navbar>
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            {menu_items.map((item, index) => (
              <Nav.Item key={index}><Nav.Link as={NavLink} to={item}>{t(item)}</Nav.Link></Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default observer(BikeEditMenu);