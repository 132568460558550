import $api from "../http/http";

export default class AuthService {
    static async login(email, password) {
        return $api.post('users/login', {email, password});
    }

    static async registration(email, password, firstName, lastName) {
        return $api.post('users/register', {email, password, firstName, lastName});
    }

    static async logout() {
        return $api.post('users/logout');
    }

}

