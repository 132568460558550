import React, { useEffect, useState } from 'react';
import { Alert } from "react-bootstrap";

const DisappearingAlert = ({ variant = "success", duration = 3000, children }) => {
  // the alert is displayed by default
  const [showAlert, setAlert] = useState(true);

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    const timeId = setTimeout(() => {
      setAlert(false);
    }, duration);

    return () => {
      clearTimeout(timeId)
    }
  }, [duration]);

  // if (!showAlert) return;

  return (
      <Alert show={showAlert} variant={variant}>{children}</Alert>
  );
}

export default DisappearingAlert;
