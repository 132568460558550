import React from 'react';
import { ReactComponent as Logo } from '../imgs/bikepassport_logo_web.svg';



function Footer() {

  return (
    <>
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
              {/* <img
                alt=""
                src={logo}
                width="40px"
                className="me-3 d-inline-block align-center"
              /> */}
              <Logo width="40px" height="57px" className="me-3 d-inline-block align-center" />
            </a>
            <span className="mb-3 mb-md-0 text-muted">&copy; 2022 BikePassport, Inc</span>
          </div>
        </footer>
      </div>

    </>
  )
}

export default Footer;