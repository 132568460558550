import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { StoreContext } from "../context/StoreContext";
import { observer } from "mobx-react-lite";

import { useForm } from "react-hook-form";
import DisappearingAlert from "./DisappearingAlert";



const UserSettings = () => {
  const { t } = useTranslation();
  const { store } = useContext(StoreContext)

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [showAlert, setAlert] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: store.user
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setAlert(false);
    setError("");

    const genericErrorMessage = t("error_message.try_later")

    store.updateUser(data)
    .then(() => {
      setAlert(true);
    })
    .catch((err) => {
      if (err === 400) {
        setError(t("error_message.bad_request"))
      } else if (err === 401) {
        setError(t("error_message.unauthorized"))
      } else {
        setError(genericErrorMessage)
      }
    })
    .finally(()=> setIsSubmitting(false))
  
  }

  useEffect(() => {
    if (!store.user) store.checkAuth();
    if (store.user) reset(store.user);
  }, [reset, store])

  if (store.isLoading) return (
    "Loading..."
  )

  return (
    <Container>
      {showAlert && <DisappearingAlert>{t("user_settings_updated_successfully")}</DisappearingAlert>}
      {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-1" controlId="firstName">
          <Form.Label >{t("first_name")}</Form.Label>
          <Form.Control placeholder={t("first_name_example")} {...register("firstName")} />

        </Form.Group>
        <Form.Group className="mb-1" controlId="familyName">
          <Form.Label >{t("last_name")}</Form.Label>
          <Form.Control placeholder={t("last_name_example")} {...register("lastName")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="email">
          <Form.Label >{t("email")}</Form.Label>
          <Form.Control
          disabled
          type="email"
          isInvalid={!!errors.email}
          placeholder={t("email_example")}
          {
            ...register("email",
            { required: true })
          }
          />
          <Form.Control.Feedback type="invalid">
            {t("mandatory_email_is_empty")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-1" controlId="phone">
          <Form.Label >{t("phone")}</Form.Label>
          <Form.Control placeholder={t("phone_example")} {...register("phone")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="country">
          <Form.Label >{t("country")}</Form.Label>

          {/* <Typeahead
            onChange={handleChange}
            id="country_selector"
            options={countries}
            placeholder={t("country_example")}
            defaultInputValue={user.details.country}
          /> */}
          <Form.Control placeholder={t("country_example")} {...register("country")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="city">
          <Form.Label >{t("city")}</Form.Label>
          <Form.Control placeholder={t("city_example")} {...register("city")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="postalCode">
          <Form.Label >{t("postal_code")}</Form.Label>
          <Form.Control placeholder={t("postal_code_example")} {...register("postalCode")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="street">
          <Form.Label >{t("street")}</Form.Label>
          <Form.Control placeholder={t("street_example")} {...register("street")} />
        </Form.Group>
        {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {t("save_changes")}
        </Button>
      </Form >
    </Container>
  );
};

export default observer(UserSettings);