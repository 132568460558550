// import { Button, Callout, FormGroup, InputGroup } from "@blueprintjs/core"
import { Button, Alert, Container, Form } from "react-bootstrap";
import React, { useContext, useState } from "react"
import { StoreContext } from "../context/StoreContext";

import { useTranslation } from "react-i18next";


const Register = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const {store} = useContext(StoreContext)

  const { t } = useTranslation();

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError("");

    const genericErrorMessage = t("error_message.try_later")

    store.registration(email, password, firstName, lastName)
      .catch((err) => {
        if (err.status === 400) {
          setError(t("error_message.bad_request"));
          if (err.data.message === "user_already_exists") setError(t("error_message.user_already_exists"));
        } else if (err.status === 401) {
          setError(t("error_message.unauthorized"));
        } else {
          setError(genericErrorMessage)
        }

      })
    setIsSubmitting(false);

  }

  return (
    <Container>
      {error && <Alert  className="mt-3" variant="danger">{error}</Alert>}

      <Form onSubmit={formSubmitHandler}>
        <Form.Group className="mb-3">
          <Form.Label>{t("first_name")}</Form.Label>
          <Form.Control
            type="text"
            autoFocus
            id="firstName"
            placeholder={t("first_name_example")}
            onChange={e => setFirstName(e.target.value)}
            value={firstName}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("last_name")}</Form.Label>
          <Form.Control
            type="text"
            id="lastName"
            placeholder={t("last_name_example")}
            onChange={e => setLastName(e.target.value)}
            value={lastName}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("email_example")}
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("password_example")}
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button
          variant="primary"
          disabled={isSubmitting}
          type="submit"
        >
          {`${isSubmitting ? "Registering" : "Register"}`}
        </Button>
      </Form>
    </Container>
  )
}

export default Register
