import { Button, Alert, Container, Form } from "react-bootstrap";

import React, { useState } from "react";
import UserService from "../services/UserService";
import { useForm } from "react-hook-form";



import { useTranslation } from "react-i18next";
import DisappearingAlert from "./DisappearingAlert";


const PwdUpdate = () => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [showAlert, setAlert] = useState(false);

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setAlert(false);
    setError("");

    const genericErrorMessage = t("error_message.try_later")

    UserService.updatePassword(data.oldPassword, data.newPassword)
      .then(() => {
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          setError(t("error_message.bad_request"));
          if (err.response.data.message === "no_such_user") setError(t("error_message.no_such_user"));
          if (err.response.data.message === "incorrect_password") setError(t("error_message.incorrect_password"));
        } else if (err.status === 401) {
          setError(t("error_message.unauthorized"));
        } else {
          setError(genericErrorMessage)
        }
      })
      .finally(() => setIsSubmitting(false))

  }

  return (
    <Container>
      {showAlert && <DisappearingAlert>{t("password_updated_successfully")}</DisappearingAlert>}
      {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-1" controlId="oldPassword">
          <Form.Label >{t("password_old")}</Form.Label>

          <Form.Control
            type="password"
            placeholder={t("password")}
            isInvalid={!!errors.oldPassword}
            {
            ...register("oldPassword",
              { required: true }
            )
            } />

        </Form.Group>
        <Form.Group className="mb-1" controlId="newPassword">
          <Form.Label >{t("password_new")}</Form.Label>

          <Form.Control
            type="password"
            placeholder={t("password")}
            isInvalid={!!errors.newPassword}
            {...register(
              "newPassword",
              { required: true, minLength: 6 }
            )
            }
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="confirmPassword">
          <Form.Label >{t("password_confirm")}</Form.Label>

          <Form.Control
            type="password"
            placeholder={t("password")}
            isInvalid={!!errors.confirmPassword}
            {
            ...register("confirmPassword",
              {
                validate: (pass) => {
                  return (pass === getValues("newPassword") || t("passwords_are_not_equal"));
                }
              }
            )} />
          <Form.Control.Feedback type="invalid">
            {errors.confirmPassword && errors.confirmPassword.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit">
          {t("save_changes")}
        </Button>
      </Form >
    </Container>
  )
}

export default PwdUpdate;
