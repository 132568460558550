import React from 'react';
import Jumbotron from '../components/Jumbotron';
import Cards from '../components/Cards';

const Owners = () => (
  <>
    <Jumbotron />
    <Cards />
  </>
)


export default Owners;