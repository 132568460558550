import { useContext } from "react"
import { Outlet, Navigate } from "react-router-dom";

import { StoreContext } from "../context/StoreContext";
import { observer } from "mobx-react-lite";

// import Loader from "../components/Loader"

import { useTranslation } from "react-i18next";

import { Container } from "react-bootstrap";
// import BreadCrumbs from "../components/BreadCrumbs";



function My() {
  const { store } = useContext(StoreContext)

  const { t } = useTranslation();
  // If uncommented, then rerenders My-page with loader and fails to render Disappearing alert on successfull settings update
  // if (store.isLoading) {
  //   return (
  //     <Loader />
  //   );
  // }

  if (!store.isAuth) {
    return (
      <Navigate to="/auth" />
    );
  }

  if (store.user.isActivated === false) return (
    <Container><h1 className="text-center">{t("user_is_not_activated")}</h1></Container>
  );

  return (
    <Container>
      {/* <BreadCrumbs /> */}

      <Outlet />
    </Container>
  )

}

export default observer(My);