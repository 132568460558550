import { useContext, useState, useMemo } from "react";
import { Container, Form, Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StoreContext } from "../context/StoreContext";
import { observer } from "mobx-react-lite";


import Select from "react-select";
import countries from 'react-select-country-list'
import chroma from 'chroma-js';

import { useForm, Controller } from "react-hook-form";
import DisappearingAlert from "../components/DisappearingAlert";


const BikeDetails = () => {
  const { t } = useTranslation();
  const { store } = useContext(StoreContext);

  const countryList = useMemo(() => countries().native().nativeData, [])


  const bikeList = [
    { value: "Bike", label: "Bike" },
    { value: "Bike Trailer", label: "Bike Trailer" },
    { value: "BMX ", label: "BMX " },
    { value: "Cargo Bike", label: "Cargo Bike" },
    { value: "Cargo Tricycle", label: "Cargo Tricycle" },
    { value: "City ", label: "City " },
    { value: "Cruiser ", label: "Cruiser " },
    { value: "Cyclocross ", label: "Cyclocross " },
    { value: "Electric bike", label: "Electric bike" },
    { value: "E-Scooter", label: "E-Scooter" },
    { value: "E-Skateboard", label: "E-Skateboard" },
    { value: "Fat ", label: "Fat " },
    { value: "Fitness ", label: "Fitness " },
    { value: "Flat-foot comfort ", label: "Flat-foot comfort " },
    { value: "Folding ", label: "Folding " },
    { value: "Gravel", label: "Gravel" },
    { value: "Hybrid ", label: "Hybrid " },
    { value: "Kids ", label: "Kids " },
    { value: "Mountain ", label: "Mountain " },
    { value: "Pedi Cab (rickshaw)", label: "Pedi Cab (rickshaw)" },
    { value: "Penny Farthing", label: "Penny Farthing" },
    { value: "Personal mobility device", label: "Personal mobility device" },
    { value: "Recumbent ", label: "Recumbent " },
    { value: "Road ", label: "Road " },
    { value: "Stroller", label: "Stroller" },
    { value: "Tall Bike", label: "Tall Bike" },
    { value: "Tandem", label: "Tandem" },
    { value: "Time Trial ", label: "Time Trial " },
    { value: "Touring ", label: "Touring " },
    { value: "Track/Fixed-gear", label: "Track/Fixed-gear" },
    { value: "Trail behind (half bike)", label: "Trail behind (half bike)" },
    { value: "Triathlon ", label: "Triathlon " },
    { value: "Tricycle", label: "Tricycle" },
    { value: "Unicycle", label: "Unicycle" },
    { value: "Wheelchair", label: "Wheelchair" },
  ]

  const frameSizeList = [
    { value: "XXS", label: "XXS" },
    { value: "XS", label: "XS" },
    { value: "S", label: "S" },
    { value: "M", label: "M" },
    { value: "L", label: "L" },
    { value: "XL", label: "XL" },
    { value: "XXL", label: "XXL" },
  ]

  const frameMaterialList = [
    { value: "aluminium", label: t("aluminium") },
    { value: "carbon", label: t("carbon") },
    { value: "steel", label: t("steel") },
    { value: "titanium", label: t("titanium") },
    { value: "magnesium", label: t("magnesium") },
    { value: "organic", label: t("organic") },
  ]

  const wheelSizeList = [
    { value: "29in/700c", label: "29in/700c" },
    { value: "27.5in/650b", label: "27.5in/650b" },
    { value: "27in", label: "27in" },
    { value: "26in", label: "26in" },
    { value: "24in", label: "24in" },
    { value: "20in", label: "20in" },
    { value: "18in", label: "18in" },
    { value: "16in", label: "16in" },
    { value: "14in", label: "14in" },
    { value: "12in", label: "12in" },
    { value: "32in", label: "32in" },

  ]

  const { bikeId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [showAlert, setAlert] = useState(false);

  const currentBike = store.bikes.find(bike => bike.id === bikeId);

  const { control, register, handleSubmit } = useForm({
    defaultValues: currentBike
  });

  const currentYear = new Date().getFullYear();
  const yearOptions = [...Array(100).keys()].map(i => {
    const year = (currentYear - i);
    return { "value": year, "label": year.toString() }
  });

  const colourOptions = [
    { value: "black", label: t("black"), color: "#000000" },
    { value: "silver", label: t("silver"), color: "#C0C0C0" },
    { value: "gray", label: t("gray"), color: "#808080" },
    { value: "white", label: t("white"), color: "#FFFFFF" },
    { value: "maroon", label: t("maroon"), color: "#800000" },
    { value: "red", label: t("red"), color: "#FF0000" },
    { value: "purple", label: t("purple"), color: "#800080" },
    { value: "fuchsia", label: t("fuchsia"), color: "#FF00FF" },
    { value: "green", label: t("green"), color: "#008000" },
    { value: "lime", label: t("lime"), color: "#00FF00" },
    { value: "olive", label: t("olive"), color: "#808000" },
    { value: "yellow", label: t("yellow"), color: "#FFFF00" },
    { value: "navy", label: t("navy"), color: "#000080" },
    { value: "blue", label: t("blue"), color: "#0000FF" },
    { value: "teal", label: t("teal"), color: "#008080" },
    { value: "aqua", label: t("aqua"), color: "#00FFFF" },
  ];

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.4).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : chroma(data.color).name() === 'white' ? 'black' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: chroma(data.color).name() === 'white' ? 'black' : data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: chroma(data.color).name() === 'white' ? 'black' : data.color,
      ':hover': {
        backgroundColor: data.color,
        color: chroma(data.color).name() === 'white' ? 'black' : 'white',
      },
    }),
  };
  //setting CurrentBike data before useForm
  // useEffect(() => {
  //   store.refreshCurrentBike(bikeId)
  //   .then(reset(store.currentBike));
  // }, [reset, bikeId]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setAlert(false);
    setError("");

    const genericErrorMessage = t("error_message.try_later")

    store.updateBike(data)
      .then(() => {
        setAlert(true);
      })
      .catch((err) => {
        if (err === 400) {
          setError(t("error_message.bad_request"))
        } else if (err === 401) {
          setError(t("error_message.unauthorized"))
        } else {
          setError(genericErrorMessage)
        }
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <Container className="my-3 col-lg-6">
      {showAlert && <DisappearingAlert>{t("user_settings_updated_successfully")}</DisappearingAlert>}
      {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-1" controlId="bikeType">
          <Form.Label >{t("bike_type")}</Form.Label>
          {/* <Form.Select {...register("bikeType")}>
            {bikes.map((bike, index) =>
              <option value={bike.bike_type}>{bike.desc}</option>
            )}
          </Form.Select> */}
          <Controller
            name="bikeType"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={bikeList.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={bikeList}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="serialNumber">
          <Form.Label >{t("frame_serial_number")}</Form.Label>
          <Form.Control  {...register("serialNumber")} />
          <Form.Text>{t("serial_number_desc")}</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1" controlId="manufacturer">
          <Form.Label >{t("bike_manufacturer")}</Form.Label>
          <Form.Control {...register("manufacturer")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="model">
          <Form.Label >{t("bike_model")}</Form.Label>
          <Form.Control {...register("model")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="year">
          <Form.Label >{t("bike_year")}</Form.Label>
          <Controller
            name="year"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={yearOptions.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={yearOptions}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="primaryColors">
          <Form.Label >{t("bike_color")}</Form.Label>
          <Controller
            name="primaryColors"
            control={control}
            defaultValue={colourOptions.map(c => c.value)}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                closeMenuOnSelect={false}
                isMulti
                inputRef={ref}
                value={colourOptions.filter(cl => value.includes(cl.value))}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.map(op => op.value)); }}
                options={colourOptions}
                styles={colourStyles}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="description">
          <Form.Label >{t("bike_description")}</Form.Label>
          <Form.Control {...register("description")} as="textarea" />
        </Form.Group>

        <Form.Group className="mb-1" controlId="frameSize">
          <Form.Label >{t("frame_size")}</Form.Label>
          {/* <Form.Control {...register("frameSize")} /> */}
          <Controller
            name="frameSize"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={frameSizeList.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={frameSizeList}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="frameMaterial">
          <Form.Label >{t("frame_material")}</Form.Label>
          {/* <Form.Control {...register("frameMaterial")} /> */}
          <Controller
            name="frameMaterial"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={frameMaterialList.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={frameMaterialList}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="wheelsSize">
          <Form.Label >{t("wheels_size")}</Form.Label>
          {/* <Form.Control {...register("wheelsSize")} /> */}
          <Controller
            name="wheelsSize"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={wheelSizeList.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={wheelSizeList}
              />}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="country">
          <Form.Label >{t("country")}</Form.Label>
          {/* <Form.Control {...register("location.country")} /> */}
          <Controller
            name="location.country"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                inputRef={ref}
                value={countryList.find((c) => c.value === value)}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.value); }}
                options={countryList}
              />}
          />
          <Form.Text>{t("country_example")}</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1" controlId="city">
          <Form.Label >{t("city")}</Form.Label>
          <Form.Control {...register("location.city")} />
        </Form.Group>

        <Form.Group className="mb-1" controlId="street">
          <Form.Label >{t("street")}</Form.Label>
          <Form.Control {...register("location.street")} />
        </Form.Group>


        <Button variant="primary" type="submit">
          {t("save_changes")}
        </Button>
      </Form >
    </Container>
  );
}

export default observer(BikeDetails);