import React, { useEffect, useContext } from "react"

import { useTranslation } from "react-i18next";
import { Card, Button, Container, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import { StoreContext } from "../context/StoreContext";
import { observer } from "mobx-react-lite";
import { BsPencilSquare, BsPlus } from "react-icons/bs";

// TODO translate


const Bikes = () => {
  const { t } = useTranslation();
  const { store } = useContext(StoreContext);

  useEffect(() => {
    //always update store.bikes on page load
    // if (!store.bikes || store.bikes === [] || store.bikes.length === 0) {
    store.getMyBikes();
    // }
  }, [store]);

  return (
    <Container className="p-3">
      <Row xs="auto">
        {store.bikes && store.bikes.map((bike) => (
          <Col key={bike.id} md={6} lg={4} className="mb-2">
            <Card>
              <Link to={"/my/bikes/" + bike.id + "/edit"}>
                <Card.Img
                  variant="top"
                  src={(bike.photos.bike.length > 0) ? "/photos/" + bike.photos.bike[0] : '/Mountainbike.svg'}
                /></Link>
              <Card.Header as={Link} to={"/my/bikes/" + bike.id + "/edit"} style={{ textDecoration: "none", color: "inherit" }}>
                <h5>{bike.manufacturer + " " + bike.model + " "}{bike.year && bike.year}</h5>
              </Card.Header>
              <Card.Body className="text-end" style={{ position: 'absolute', top: 0, right: 0, padding: '1rem' }}>
                <Button size="sm" variant="primary" as={Link} to={"/show/" + bike.bikeLink} >{t("passport")}</Button>{' '}
                <Button size="sm" variant="secondary" as={Link} to={"/my/bikes/" + bike.id + "/edit"}><BsPencilSquare /></Button>
              </Card.Body>
            </Card>
          </Col>
        ))
        }

        <Col>
          <Card>
            <Card.Header as="h5">{t("get_passport")}</Card.Header>
            <Card.Body>
              <Container className="d-grid gap-2">
                <Button as={Link} to="/my/bikes/add" variant="success"><BsPlus />{t("add_bike")}</Button>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default observer(Bikes);