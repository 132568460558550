import React from 'react';
import { useCallback, useContext, useEffect } from "react"
import { useRoutes } from "react-router-dom";
import { getAppRoutes } from "./router"

import { StoreContext } from "./context/StoreContext";
import { observer } from "mobx-react-lite";


import 'bootstrap/dist/css/bootstrap.min.css';

import NaviBar from './components/Navibar';
import Footer from './components/Footer';


function App() {
  const { store } = useContext(StoreContext)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.checkAuth()
    }
  }, [])

  /**
   * Sync logout across tabs
   */
  const syncLogout = useCallback(event => {
    if (event.key === "logout") {
      // If using react-router-dom, you may call history.push("/")
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("storage", syncLogout)
    return () => {
      window.removeEventListener("storage", syncLogout)
    }
  }, [syncLogout])
  const appRoutes = getAppRoutes();
  const routeElements = useRoutes(appRoutes);

  return (
    <>
      <NaviBar />
      {routeElements}
      {/* <Routes>
        <Route path="/">
          <Route index element={<Owners />} />
          <Route path="about" element={<About />} />
          <Route path="owners" element={<Owners />} />
          <Route path="workshops" element={<Workshops />} />
          <Route path="shops" element={<Shops />} />
          <Route path="auth" element={<AuthScreen />} />
          <Route path="show/:bikePage" element={<Passport />} />
          <Route path="my" element={<My />} >
            <Route index element={<Bikes />} />
            <Route path="settings" element={<Settings />} />
            <Route path="bikes" element={<Bikes />} />
            <Route path="deals" element={<Deals />} />
            <Route path="bikes/add" element={<AddBike />} />
            <Route path="bikes/:bikeId" element={<BikeEdit />} />
            <Route path="bikes/:bikeId/edit" element={<BikeEdit />}>
              <Route index element={<BikeDetails />} />
              <Route path="details" element={<BikeDetails />} />
              <Route path="photos" element={<BikePhotos />} />
              <Route path="parts" element={<BikeParts />} />
              <Route path="parts/add" element={<AddEditPart />} />
              <Route path="parts/:partId/edit" element={<AddEditPart />} />
              <Route path="incidents" element={<BikeIncidents />} />
              <Route path="service" element={<BikeService />} />
              <Route path="documents" element={<BikeDocuments />} />
              <Route path="stickers" element={<BikeStickers />} />
            </Route>
          </Route>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes> */}
      <Footer />
    </>
  );
}

export default observer(App);
