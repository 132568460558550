import $api from "../http/http";
// import Resizer from "react-image-file-resizer";

export default class BikeService {
    static async addNewBike(bikeData) {
        return $api.post('bikes/add', {
            "serialNumber": bikeData.serialNumber,
            "manufacturer": bikeData.manufacturer,
            "model": bikeData.model,
            "primaryColors": bikeData.primaryColors
        });
    }

    static async getCurrentUserBikes() {
        return $api.get('bikes/getMyBikes');
    }

    static async getBike(bikeId) {
        return $api.get('bikes/getBike', { "params": { "bikeId": bikeId } });
    }

    static async getBikeByLink(linkId) {
        return $api.get('bikes/getBikeByLink', { "params": { "linkId": linkId } });
    }

    static async getBikeByLink4Auth(linkId) {
        return $api.get('bikes/getBikeByLink4Auth', { "params": { "linkId": linkId } });
    }

    static async updateBike(bikeData) {
        return $api.post('bikes/update', {
            "id": bikeData.id,
            "bikeType": bikeData.bikeType,
            "serialNumber": bikeData.serialNumber,
            "manufacturer": bikeData.manufacturer,
            "model": bikeData.model,
            "year": bikeData.year,
            "primaryColors": bikeData.primaryColors,
            "description": bikeData.description,
            "frameSize": bikeData.frameSize,
            "frameMaterial": bikeData.frameMaterial,
            "wheelsSize": bikeData.wheelsSize,
            "location": bikeData.location,
        });
    }

    static async uploadPhotos_(bikeId, data) {
        const formData = new FormData();
        formData.append('bikeId', bikeId);
        if (!data.bikePhotos && !data.snPhoto && !data.bikeWithOwnerPhotos) {
            throw new Error('no_files_submitted');
        }
        if (data.bikePhotos)
            for (let i = 0; i <= data.bikePhotos.length - 1; i++)
                formData.append('bikePhotos', data.bikePhotos[i]);
        if (data.snPhoto) formData.append('snPhoto', data.snPhoto[0]);
        if (data.bikeWithOwnerPhotos)
            for (let i = 0; i <= data.bikeWithOwnerPhotos.length - 1; i++)
                formData.append('bikeWithOwnerPhotos', data.bikeWithOwnerPhotos[i]);

        return $api.post('bikes/uploadFile', formData, {
            "Content-Type": "multipart/form-data"
        });
    }

    static async uploadPhotos(bikeId, data) {

        if (Object.keys(data).length === 0) {
            throw new Error('no_files_submitted');
        }


        // const resizeFile = (file, compressFormat) =>
        //     new Promise((resolve) => {
        //         Resizer.imageFileResizer(
        //             file,
        //             1600,
        //             1200,
        //             compressFormat,
        //             90,
        //             0,
        //             (uri) => {
        //                 resolve(uri);
        //             },
        //             "file"
        //         );
        //     });

        const allowedFileTypes = /jpeg|jpg|png/;
        const jpegChecExp = /jpeg|jpg/;

        const formData = new FormData();
        formData.append('bikeId', bikeId);
        for (let picType in data) {
            for (let i = 0; i <= data[picType].length - 1; i++) {
                const isAllowedMimeType = allowedFileTypes.test(data[picType][i].type);
                const isJpeg = jpegChecExp.test(data[picType][i].type);
                if (!isAllowedMimeType) throw new Error('invalid_file_type');
                // const compressFormat = (isJpeg ? "JPEG" : "PNG")
                // const resizedImage = await resizeFile(data[picType][i], compressFormat);
                formData.append(picType, data[picType][i]);
            }
        }

        return $api.post('bikes/uploadFile', formData, {
            "Content-Type": "multipart/form-data"
        });
    }

    static async deletePhoto(bikeId, photoType, photoIndex) {
        return $api.post('bikes/deleteBikePhoto', {
            "bikeId": bikeId,
            "photoType": photoType,
            "photoIndex": photoIndex,
        });
    }

    static async changeWithTopPhoto(bikeId, photoType, photoIndex) {
        return $api.post('bikes/changeWithTopPhoto', {
            "bikeId": bikeId,
            "photoType": photoType,
            "photoIndex": photoIndex,
        });
    }

}

