import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Feedback = () => {
  const { t } = useTranslation();

  return (
    <Container className="col-md-6 my-5 text-center">
      {t("contact_us")}
      <br />
      <a href="mailto:sales@bikepassport.io">{t("sales@bikepassport.io")}</a>
    </Container>
  )
}

export default Feedback
