import { useCallback, useContext, useEffect, useState } from "react"
import { StoreContext } from "../context/StoreContext";


import { Container, Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";

import Loader from "./Loader"
import Login from "./Login"
import Register from "./Register"

import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";


function AuthScreen() {
  const [currentTab, setCurrentTab] = useState("Login");
  const { store } = useContext(StoreContext);

  const { t } = useTranslation();

  /**
   * Sync logout across tabs
   */
  const syncLogout = useCallback(event => {
    if (event.key === "logout") {
      // If using react-router-dom, you may call history.push("/")
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("storage", syncLogout)
    return () => {
      window.removeEventListener("storage", syncLogout)
    }
  }, [syncLogout])

  if (store.isLoading) return (
    <Loader />
  )

  if (!store.isAuth) return (
    <Container className="my-3 col-sm-10  col-md-5 col-xl-4"  >
      <Tabs
        activeKey={currentTab}
        onSelect={setCurrentTab}
        className="my-3"
      >
        <Tab eventKey="Login" title={t("log_in")}>
          <Login />
        </Tab>
        <Tab eventKey="Register" title={t("register")}>
          <Register />
        </Tab>
      </Tabs>
    </Container>
  );

  return (
    <Navigate to="/my" />
  )

}

export default observer(AuthScreen)
