import { Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const Loader = () => {
  return (
    <Container  className="col-3 my-3 text-center">
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    </Container>
  )
}

export default Loader
