import { useTranslation } from "react-i18next";

const BikeDeals = () => {
  const { t } = useTranslation();
return (
  <>
  Deals
  </>
);
}

export default BikeDeals;