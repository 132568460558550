import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from 'react-bootstrap';

const languages = [
  { name: "English", code: "en" },
  { name: "German", code: "de" },
  { name: "Dutch", code: "nl" },
  { name: "Français", code: "fr" },
  { name: "Русский", code: "ru" },
];

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  return (
    <>
      <Form.Select
        size="sm"
        className="me-3"
        value={i18n.language}
        onChange={(e) =>
          i18n.changeLanguage(e.target.value)
        }
      >
        {languages.map(({ name, code }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </Form.Select>
    </>
  );
}
export default LanguageSwitcher;