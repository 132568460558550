import $api from "../http/http";

export default class UserService {
    static async getUser() {
        return $api.get('users/me');
    }

    static async updateUser(userData) {
        return $api.post('users/update', userData);
    }

    static async updatePassword(oldPassword, newPassword) {
        return $api.post('users/updatePassword', { "oldPassword": oldPassword, "newPassword": newPassword });
    }
}

