import React from 'react';
import { Button } from "react-bootstrap";
import './Jumbotron.css';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";




function Jumbotron() {
  const { t } = useTranslation();

  return (
    <>
      <div className="p-5 mb-4 bg-dark text-center jumbotron">
        <div className="container-fluid py-5">
          <h1 className="display-5 text-light fw-bold p-2"  style={{background: "rgba(60,60,60, 0.5)", borderRadius: "25px"}}>{t("motto")}</h1>
          <Link to="/my"><Button variant="success" className="btn-lg" size="lg">{t("get_passport")}</Button></Link>
          
        </div>
      </div>
    </>
  )
}

export default Jumbotron;