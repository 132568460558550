import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/UserContext"
import { StoreProvider } from "./context/StoreContext";
// import Store from "./store/store"

import App from './App';
import "./services/i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <StoreProvider>
        <UserProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UserProvider>
      </StoreProvider>
    </React.Suspense>
  </React.StrictMode >

);

