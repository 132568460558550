import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BikeIncidents = () => {
  const { t } = useTranslation();
return (
  <>
  Incidents
  </>
);
}

export default BikeIncidents;