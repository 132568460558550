import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import BikeService from "../services/BikeService";


import axios from 'axios';
// import {IUser} from "../models/IUser";
// import {AuthResponse} from "../models/response/AuthResponse";
// import {API_URL} from "../http";

export default class Store {
    user = {};
    bikes = [];
    isAuth = false;
    isLoading = false;

    constructor() {
        this.checkAuth();  //redirection to /auth, then to /my, when directly loading page http://localhost:3000/my/bikes/6332ff18320396ae560c4197/edit as store is not filled yet
        this.getMyBikes();
        makeAutoObservable(this);
    }

    setAuth(bool) {
        this.isAuth = bool;
    }

    setUser(user) {
        this.user = user;
    }

    setLoading(bool) {
        this.isLoading = bool;
    }

    setBikes(bikes) {
        this.bikes = bikes;
    }

    updateBikeInStoredBikes(bikeData) {
        this.bikes = this.bikes.map(bike => bike.id === bikeData.id ? bike = bikeData : bike);
    }

    updatePhotosByType4StoredBike(bikeId, photoType, photos) {
        this.bikes = this.bikes.map(bike => { if (bike.id === bikeId) bike.photos[photoType] = photos; return bike });
    }

    updateAllPhotos4StoredBike(bikeId, allPhotos) {
        this.bikes = this.bikes.map(bike => { if (bike.id === bikeId) bike.photos = allPhotos; return bike });
    }


    async login(email, password) {
        try {
            const response = await AuthService.login(email, password);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        }
    }

    async registration(email, password, firstName, lastName) {
        try {
            const response = await AuthService.registration(email, password, firstName, lastName);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({});
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            const response = await axios.get(process.env.REACT_APP_API_ENDPOINT + "users/refreshToken", { withCredentials: true });
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        } finally {
            this.setLoading(false);
        }
    }

    async updateUser(userData) {
        this.setLoading(true);
        try {
            const response = await UserService.updateUser(userData);
            this.setUser(response.data);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        } finally {
            this.setLoading(false);
        }
    }

    async getMyBikes() {
        if (!this.isAuth) return;
        this.setLoading(true);
        try {
            const response = await BikeService.getCurrentUserBikes();
            this.setBikes(response.data);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        } finally {
            this.setLoading(false);
        }
    }

    async updateBike(bikeData) {
        this.setLoading(true);
        try {
            const response = await BikeService.updateBike(bikeData)
            this.updateBikeInStoredBikes(response.data);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        } finally {
            this.setLoading(false);
        }
    }

    async deletePhoto(bikeId, photoType, photoIndex) {
        this.setLoading(true);
        try {
            const response = await BikeService.deletePhoto(bikeId, photoType, photoIndex);
            this.updatePhotosByType4StoredBike(bikeId, photoType, response.data);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        } finally {
            this.setLoading(false);
        }
    }

    async changeWithTopPhoto(bikeId, photoType, photoIndex) {
        this.setLoading(true);
        try {
            const response = await BikeService.changeWithTopPhoto(bikeId, photoType, photoIndex);
            this.updatePhotosByType4StoredBike(bikeId, photoType, response.data);
        } catch (e) {
            console.log(e.response?.data?.message);
            throw (e.response);
        } finally {
            this.setLoading(false);
        }
    }

    async uploadPhoto(bikeId, data) {
        this.setLoading(true);
        try {
            const response = await BikeService.uploadPhotos(bikeId, data);
            this.updateAllPhotos4StoredBike(bikeId, response.data);
        } catch (e) {
            console.log(e);
            throw (e.response);
        } finally {
            this.setLoading(false);
        }
    }

}
