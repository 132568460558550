import { Button, Alert, Container, Form } from "react-bootstrap";

import React, { useContext, useState } from "react";
import { StoreContext } from "../context/StoreContext";
import { observer } from "mobx-react-lite";

import { useTranslation } from "react-i18next";


const Login = () => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { store } = useContext(StoreContext)

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError("");

    const genericErrorMessage = t("error_message.try_later")

    store.login(email, password)
      .catch((err) => {
        if (err.status === 400) {
          setError(t("error_message.bad_request"));
          //No concat to update tranlation keys automaticaly 
          if (err.data.message === "no_user_with_such_email") setError(t("error_message.no_user_with_such_email"));
          if (err.data.message === "incorrect_password") setError(t("error_message.incorrect_password"));
        } else if (err.status === 401) {
          setError(t("error_message.unauthorized"))
        } else {
          setError(genericErrorMessage)
        }

      })
    setIsSubmitting(false);

  }

  return (
    <Container>
      {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
      <Form onSubmit={formSubmitHandler}>
        <Form.Group className="mb-3">
          <Form.Label>{t("email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("email_example")}
            id="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("password_example")}
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button
          variant="primary"
          disabled={isSubmitting}
          type="submit"
        // onClick={() => store.login(email, password)}
        >
          {`${isSubmitting ? t("logging_in") : t("log_in")}`}
        </Button>
      </Form>
    </Container>
  )
}

export default observer(Login);
