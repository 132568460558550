import React, { useState } from "react";
import { Container, Form, Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import BikeService from "../services/BikeService";
import {useNavigate} from 'react-router-dom';
import Select from "react-select";
import chroma from 'chroma-js';

const AddBike = () => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { control, register, handleSubmit } = useForm();

  const colourOptions = [
    { value: "black", label: t("black"), color: "#000000"},
    { value: "silver", label: t("silver"), color: "#C0C0C0"},
    { value: "gray", label: t("gray"), color: "#808080"},
    { value: "white", label: t("white"), color: "#FFFFFF"},
    { value: "maroon", label: t("maroon"), color: "#800000"},
    { value: "red", label: t("red"), color: "#FF0000"},
    { value: "purple", label: t("purple"), color: "#800080"},
    { value: "fuchsia", label: t("fuchsia"), color: "#FF00FF"},
    { value: "green", label: t("green"), color: "#008000"},
    { value: "lime", label: t("lime"), color: "#00FF00"},
    { value: "olive", label: t("olive"), color: "#808000"},
    { value: "yellow", label: t("yellow"), color: "#FFFF00"},
    { value: "navy", label: t("navy"), color: "#000080"},
    { value: "blue", label: t("blue"), color: "#0000FF"},
    { value: "teal", label: t("teal"), color: "#008080"},
    { value: "aqua", label: t("aqua"), color: "#00FFFF"},
  ];

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.4).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : chroma(data.color).name() === 'white' ? 'black' : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: chroma(data.color).name() === 'white' ? 'black' : data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: chroma(data.color).name() === 'white' ? 'black' : data.color,
      ':hover': {
        backgroundColor: data.color,
        color: chroma(data.color).name() === 'white' ? 'black' : 'white',
      },
    }),
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setError("");

    const genericErrorMessage = t("error_message.try_later");

    BikeService.addNewBike(data)
      .then(() => {
        navigate("/my");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setError(t("error_message.bad_request"));
          if (err.response.data.message === "bike_already_exists") setError(t("error_message.bike_already_exists"));
        } else if (err.response.status === 401) {
          setError(t("error_message.unauthorized"))
        } else {
          setError(genericErrorMessage)
        }
      })
      .finally(() => setIsSubmitting(false));
  }

  return (
    <Container className="my-3 col-lg-6">
      {error && <Alert className="mt-3" variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-1" controlId="serialNumber">
          <Form.Label >{t("frame_serial_number")}</Form.Label>
          <Form.Control {...register("serialNumber")}/>
          <Form.Text>{t("serial_number_desc")}</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1" controlId="manufacturer">
          <Form.Label >{t("bike_manufacturer")}</Form.Label>
          <Form.Control {...register("manufacturer")}/>
        </Form.Group>

        <Form.Group className="mb-1" controlId="model">
          <Form.Label >{t("bike_model")}</Form.Label>
          <Form.Control {...register("model")}/>
        </Form.Group>

        <Form.Group className="mb-1" controlId="primaryColors">
          <Form.Label >{t("bike_color")}</Form.Label>
          {/* <Form.Control {...register("primaryColors")}/> */}
          <Controller
            name="primaryColors"
            control={control}
            render={({ field: { onChange, value, name, ref } }) =>
              <Select
                closeMenuOnSelect={false}
                isMulti
                inputRef={ref}
                // value={colourOptions.filter(cl => value.includes(cl.value))}
                name={name}
                placeholder="..."
                onChange={(selectedOption) => { onChange(selectedOption.map(op => op.value)); }}
                options={colourOptions}
                styles={colourStyles}
              />}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          {t("save_changes")}
        </Button>
      </Form >
    </Container>
  );
};

export default AddBike;