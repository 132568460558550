import { Container, Form, Col, Row, Button, FloatingLabel, InputGroup, ListGroup, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsPencil, BsTrash } from "react-icons/bs";

const serviceData = [
  { "record": "Fork 50h service", "date": "21.09.2020", "price": "1500rub", "workshop": "self service" },
  { "record": "season open full service", "date": "01.02.2022", "price": "3000", "workshop": "BikeMe" },
  { "record": "change cassete and chain", "date": "05.05.2022", "price": "1500", "workshop": "FineBike" },
  { "record": "disk brake adjustment", "date": "06.07.2022", "price": "100", "workshop": "BrakeBike" },
  { "record": "Tubless tires setup", "date": "08.07.2022", "price": "600", "workshop": "StansNoTubes" },
  { "record": "", "date": "", "price": "", "workshop": "" },
]

const BikeService = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="g-2">
        <Col md={8}>
          <FloatingLabel controlId="Service_record" label="Service record">
            <Form.Control as="textarea" placeholder="Fork service" />
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel controlId="Service_date" label="Date" >
            <Form.Control type="date" name="dob" placeholder="Date of Birth" />
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel controlId="Service_price" label="Price" >
            <Form.Control placeholder="Price" />
          </FloatingLabel>
        </Col>
      </Row>
      <Button className="my-1" >{t("save_changes")}</Button>
      

      {/* <Container>
        <Row md className="border">
          <Col md={1}>#</Col>
          <Col md={4}>Record</Col>
          <Col md>Date</Col>
          <Col md>Price</Col>
          <Col md>Workshop</Col>
          <Col md={2}>
            Action
          </Col>
        </Row>
        {
          serviceData.map(({ record, date, price, workshop }, index) => {
            return (
              <Row md className="border">
                <Col md={1}>{index}</Col>
                <Col md={4}>{record}</Col>
                <Col md> {date}</Col>
                <Col md>{price}</Col>
                <Col md>{workshop}</Col>
                <Col md={2}>
                  <Button variant="outline-secondary"><BsPencil /></Button>{' '}
                  <Button variant="outline-danger"><BsTrash /></Button>
                </Col>
              </Row>
            )
          })
        }
      </Container> */}

      <Table striped bordered hover size="sm" className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Record</th>
            <th>Date</th>
            <th>Price</th>
            <th>Workshop</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            serviceData.map(({ record, date, price, workshop }, index) => {
              return (
                <tr>
                  <td>{index}</td>
                  <td>{record}</td>
                  <td>{date}</td>
                  <td>{price}</td>
                  <td>{workshop}</td>
                  <td>
                    <Button variant="outline-secondary"><BsPencil /></Button>{' '}
                    <Button variant="outline-danger"><BsTrash /></Button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </>
  );
}

export default BikeService;