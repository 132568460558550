import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import BikeEditMenu from "../components/BikeEditMenu";

const BikeEdit = () => {
  return (
    <>
      <BikeEditMenu />
      <Container>
        <Outlet />
      </Container>
    </>
  );
}

export default BikeEdit;