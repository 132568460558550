import { useState, useContext, useRef } from "react";
import { Container, Col, Row, Button, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { StoreContext } from "../context/StoreContext";
import { useParams } from "react-router-dom";
import { BsImage, BsArrowUp, BsEyeSlash, BsTrash } from "react-icons/bs";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { observer } from "mobx-react-lite";

import ReactCrop, {
  centerCrop,
  makeAspectCrop
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

let previewUrl = ''


// TODO Translation

const BikePhotosEdit = () => {
  const { t } = useTranslation();
  const { store } = useContext(StoreContext);
  const { bikeId } = useParams();

  const currentBike = store.bikes.find(bike => bike.id === bikeId);

  let images= [];
  for (const [photoType, photoArr] of Object.entries(currentBike.photos)) {
    for (let index = 0; index < photoArr.length; ++index) 
      images.push({src: "/photos/" + photoArr[index], photoType: photoType, ind: index});
  }


  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxIsOpen, setLightbox] = useState(false);
  const [photoType, setPhotoType] = useState("bikePhotos");


  const { reset } = useForm();


  const onSubmit = async () => {
    var dt = new DataTransfer();
    dt.items.add(croppedFile);
    const newData = { [photoType]: dt.files }
    store.uploadPhoto(currentBike.id, newData)
      .then(() => {
        setShowModal(false);
        reset()
      });
    // reset();
  };

  // useEffect(() => {
  //   const subscription = watch(handleSubmit(onSubmit));
  //   return () => subscription.unsubscribe();
  // }, [handleSubmit, watch]);

  const [imgSrc, setImgSrc] = useState('')
  const [croppedImageUrl, setCroppedImageUrl] = useState('')
  const [croppedFile, setCroppedFile] = useState('')
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const [crop, setCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(16 / 9)
  const [fileName, setFileName] = useState("cropped_resized.jpeg")
  const [showModal, setShowModal] = useState(false);
  const [fullscreenModal, setFullscreenModal] = useState("lg-down");


  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      setCroppedImageUrl(undefined);
      setCroppedFile(undefined);
      setFileName(undefined);
      setImgSrc(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''),)
      reader.readAsDataURL(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setShowModal(true);

    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;

      setCrop(centerCrop(makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        width,
        height,
      ),
        width,
        height
      ))
    }
  }

  async function makeClientCrop(crop) {
    if (imgRef.current && crop.width && crop.height) {
      const res = await getCroppedResizedImg(
        imgRef.current,
        crop,
        scale,
        fileName
      );
      // console.log(imgRef)
      setCroppedImageUrl(res.previewUrl);
      setCroppedFile(res.file);
    }
  }

  function getCroppedResizedImg(image, crop, scale, fileName) {
    const minWidth = 480;
    const maxWidth = 1600;

    const canvas = document.createElement('canvas');
    // const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = (image.naturalWidth > maxWidth ? maxWidth : image.naturalWidth);
    canvas.width = (canvas.width < minWidth ? minWidth : canvas.width);
    canvas.height = canvas.width / aspect;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          const file = new File([blob], fileName, { type: 'image/jpeg' });
          if (previewUrl) {
            URL.revokeObjectURL(previewUrl)
          }
          previewUrl = window.URL.createObjectURL(blob);
          resolve({ previewUrl, file });
        },
        'image/jpeg',
        0.94
      );
    });
  }

  return (
    <>
      <Lightbox
        open={lightboxIsOpen}
        close={() => setLightbox(false)}
        slides={images}
        index={photoIndex}
        plugins={[Thumbnails, Zoom]}
      />


      <input className="d-none" ref={inputRef} type="file" accept="image/*" onChange={onSelectFile} />
      <br />

      <Modal show={showModal} fullscreen={fullscreenModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(crop, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => makeClientCrop(c)}
              aspect={aspect}
              ruleOfThirds
              locked
            >
              {/* <Form.Range defaultValue={scale} min="0" max="2" step="0.1" onChange={(e) => { setScale(e.target.value) }} /> */}
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}

          {/* {croppedImageUrl && (
            <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
          )} */}
          <Button onClick={() => onSubmit()}>{t("save_changes")}</Button>
        </Modal.Body>
      </Modal>
      <Container className="col-10">
        <Button
          variant="primary"
          onClick={() => {
            setPhotoType("bikePhotos");
            inputRef.current.click();
          }}>
          <BsImage />{' '}{t("add_bike_photos")}</Button>{' '}
        <Button
          variant="primary"
          onClick={() => {
            setPhotoType("snPhoto")
            inputRef.current.click();
          }}>
          <BsImage />{' '}{t("add_bike_serial_number_photo")}</Button>{' '}
        <Button
          variant="primary"
          onClick={() => {
            setPhotoType("bikeWithOwnerPhotos")
            inputRef.current.click();
          }}>
          <BsImage />{' '}{t("add_bike_with_owner_photos")}</Button>{' '}
        <Button
          variant="primary"
          onClick={() => {
            setPhotoType("docsPhotos")
            inputRef.current.click();
          }}>
          <BsImage />{' '}{t("add_documents_photos")}</Button>{' '}
        <br />
        <br />
        <Row>
          {images.map((image, i, arr) => {
            const arrLen = arr.length;
            const photoCard = <Card key={i}>
              <Card.Img
                onClick={() => {
                  setLightbox(true);
                  setPhotoIndex(i);
                }}
                variant="top"
                src={image.src} />
              <Card.Body className="text-end" style={{ position: 'absolute', top: 0, right: 0, padding: '1rem' }}>
                {(image.photoType !== 'bike') &&
                  <span title={t("private")} className="text-warning"><BsEyeSlash /></span>}
                {(i > 0 && image.photoType === 'bike') && <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => store.changeWithTopPhoto(bikeId, image.photoType, image.ind)}
                >
                  <BsArrowUp />
                </Button>}{' '}
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    store.deletePhoto(bikeId, image.photoType, image.ind);
                  }}
                >
                  <BsTrash />
                </Button>

              </Card.Body>
            </Card>
            if (i === 0) return <Col lg={12} className="px-0">{photoCard}</Col>
            const modRes = (arrLen - 1) % 4;
            // console.log(modRes)
            if (modRes === 3 && i < 1 + modRes) return <Col lg={4} className="px-0">{photoCard}</Col>
            if (modRes === 2 && i <= (1 + modRes) * 2 && arrLen < 4) return <Col lg={6} className="px-0">{photoCard}</Col>
            if (modRes === 2 && i <= (1 + modRes) * 2) return <Col lg={4} className="px-0">{photoCard}</Col>
            if (modRes === 1 && i <= 1 + 1) return <Col lg={6} className="px-0">{photoCard}</Col>
            if (modRes === 1 && i <= 1 + 4) return <Col lg={4} className="px-0">{photoCard}</Col>
            return <Col lg={3} className="px-0">{photoCard}</Col>
          })

          }
        </Row>
      </Container>



    </>
  );
}

export default observer(BikePhotosEdit);
// export default BikePhotosEdit;