import i18n from "i18next";

import About from './pages/About';
import Owners from './pages/Owners';
import Workshops from './pages/Workshops';
import Shops from './pages/Shops';
import My from './pages/My';
import NoPage from "./pages/NoPage";
import Settings from "./pages/Settings";
import AddBike from "./pages/AddBike";
import AuthScreen from "./components/AuthScreen";
import BikeEdit from './pages/BikeEdit';
import Bikes from './pages/Bikes';
import Deals from './pages/Deals';
import BikeDetails from './pages/BikeDetails';
import BikeParts from './pages/BikeParts';
import BikeService from './pages/BikeService';
import BikeDocuments from './pages/BikeDocuments';
import BikeStickers from './pages/BikeStickers';
import BikeIncidents from './pages/BikeIncidents';
import AddEditPart from './pages/AddEditPart';
import Passport from './pages/Passport';
import BikePhotosEdit from "./pages/BikePhotosEdit";
import Manufacturers from "./pages/Manufacturers";



export function getAppRoutes() {
  const appRoutes = [
    {
      path: "/",
      children: [
        { index: true, element: <Owners /> },
        { path: "about", element: <About /> },
        { path: "owners", element: <Owners /> },
        { path: "workshops", element: <Workshops /> },
        { path: "shops", element: <Shops /> },
        { path: "manufacturers", element: <Manufacturers /> },
        { path: "auth", element: <AuthScreen /> },
        { path: "show/:bikePage", element: <Passport /> },
        {
          path: "my",
          element: <My />,
          breadcrumb: i18n.t("breadcrumb_my"),
          children: [
            { index: true, element: <Bikes /> },
            { path: "settings", element: <Settings /> },
            { path: "bikes", element: <Bikes /> },
            { path: "deals", element: <Deals /> },
            { path: "bikes/add", element: <AddBike /> },
            { path: "bikes/:bikeId", element: <BikeEdit /> },
            {
              path: "bikes/:bikeId/edit",
              element: <BikeEdit />,
              children: [
                { index: true, element: <BikeDetails /> },
                { path: "details", element: <BikeDetails /> },
                { path: "photos", element: <BikePhotosEdit /> },
                { path: "parts", element: <BikeParts /> },
                { path: "parts/add", element: <AddEditPart /> },
                { path: "parts/:partId/edit", element: <AddEditPart /> },
                { path: "incidents", element: <BikeIncidents /> },
                { path: "service", element: <BikeService /> },
                { path: "documents", element: <BikeDocuments /> },
                { path: "stickers", element: <BikeStickers /> },
              ]
            },
            { path: "*", element: <NoPage /> },
          ]
        },
      ]
    }
  ]

  return appRoutes;
}