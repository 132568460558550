import { Container, Form, Col, Row, Button, FloatingLabel, InputGroup, ListGroup, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsPencil, BsTrash } from "react-icons/bs";

const stickerData = [
  { "organization": "Bikepassport", "sn": "085908235", "reg_date": "24.01.2021", "photo": "qwe" },
  { "organization": "Yandex", "sn": "0987435", "reg_date": "16.12.2020", "photo": "qwe" },
  { "organization": "BikeIndex", "sn": "085908235", "reg_date": "07.01.2021", "photo": "qwe" },
  { "organization": "BikeRegister", "sn": "085908235", "reg_date": "18.12.2020", "photo": "qwe" },

]

const BikeStickers = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="g-2">
        <Col md={6}>
          <FloatingLabel controlId="sticker_organization" label="Organization">
            <Form.Control placeholder="Organization" />
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel controlId="sticker_sn" label="Serial number" >
            <Form.Control placeholder="Serial number"/>
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel controlId="sticker_reg_date" label="Registration date" >
            <Form.Control type="date" />
          </FloatingLabel>
        </Col>
        <Col md={2}>
          <FloatingLabel controlId="sticker_photo" label="Photo" >
            <Form.Control placeholder="Photo"/>
          </FloatingLabel>
        </Col>
      </Row>
      <Button className="my-1" >{t("save_changes")}</Button>


      <Table striped bordered hover size="sm" className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Organization</th>
            <th>Serial Number</th>
            <th>Reg Date</th>
            <th>Photo</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            stickerData.map(({ organization, sn, reg_date, photo }, index) => {
              return (
                <tr>
                  <td>{index}</td>
                  <td>{organization}</td>
                  <td>{sn}</td>
                  <td>{reg_date}</td>
                  <td>{photo}</td>
                  <td>
                    <Button variant="outline-secondary"><BsPencil /></Button>{' '}
                    <Button variant="outline-danger"><BsTrash /></Button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </>
  );
}

export default BikeStickers;