import React from "react"
import { Container } from "react-bootstrap";

import PwdUpdate from "../components/PwdUpdate";
import UserSettings from "../components/UserSettings";


const Settings = () => {

  return (
    <Container className="my-3 col-lg-6">
      <UserSettings/>

      <br />
      <br />

      <PwdUpdate/>
    </Container>
  );
};

export default Settings;