import { ReactComponent as Logo } from '../imgs/bikepassport_logo_web.svg';

import { Nav, Navbar, NavDropdown, Container, Dropdown, Button } from "react-bootstrap";
import LanguageSwitcher from "./LanguageSwitcher";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useContext, useEffect } from "react"
import { StoreContext } from "../context/StoreContext";
import { observer } from "mobx-react-lite";
import { BsGear } from 'react-icons/bs';


// TODO translation

function NaviBar() {
  // const [user, setUser] = useContext(UserContext)
  const { store } = useContext(StoreContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (!store.user) store.checkAuth();
  }, [])

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Link to="/" className="d-flex align-items-center navbar-brand">
            <Logo  width="80px" height="114px" className="me-2 d-inline-block align-center"/>
            <span><b>BIKEPASSPORT</b></span>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <Nav.Item><Link className="nav-link" to="/owners">{t("owners")}</Link></Nav.Item>
              <Nav.Item><Link className="nav-link" to="/workshops">{t("workshops")}</Link></Nav.Item>
              <Nav.Item><Link className="nav-link" to="/shops">{t("shops")}</Link></Nav.Item>
              <Nav.Item><Link className="nav-link" to="/manufacturers">{t("manufacturers")}</Link></Nav.Item>
            </Nav>
            <Nav>
              <LanguageSwitcher />
              {store.isAuth === false ? (
                <Button className="nav-link" as={Link} to="/auth" variant="secondary">
                  <BsGear size={32} />
                </Button>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle variant="secondary">
                    <BsGear size={32} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="dark">
                    {store.user.isActivated === true ? (
                      <>
                        <NavDropdown.Item as={Link} to="/my/bikes">{t("my_bikes")}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/my/settings">{t("settings")}</NavDropdown.Item>
                      </>

                    ) : null}

                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => store.logout()}>{t("log_out")}</NavDropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )
              }

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default observer(NaviBar);