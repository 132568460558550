import { useContext, useState, useEffect } from "react";
import { Container, Card, Placeholder, Row, Col, Collapse, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import BikeService from "../services/BikeService";
import { StoreContext } from "../context/StoreContext";
// import { QRCodeSVG } from 'qrcode.react';
import Lightbox from "yet-another-react-lightbox";
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { BsPlusCircle, BsDashCircle, BsEyeSlash, BsPencilSquare } from 'react-icons/bs';
import { observer } from "mobx-react-lite";

const CollapseItem = (props) => {
  const [collapsSpec, setCollapseSpec] = useState(false);
  const { store } = useContext(StoreContext);


  return (
    <div className="border-top border-bottom py-3">
      <h4 className="h4 hstack" onClick={() => setCollapseSpec(!collapsSpec)}>
        <span>{props.title}</span>
        {(props.isOwner) && store.isAuth &&
          <Button
            as={Link}
            to={"/my/bikes/" + props.bikeId + "/edit/parts"}
            className="ms-1"
            size="sm"
            variant="secondary">
            <BsPencilSquare />
          </Button>
        }
        <span className="ms-auto">{collapsSpec ? <BsDashCircle /> : <BsPlusCircle />}</span>
      </h4>
      <Collapse in={collapsSpec}>
        <Container>
          {props.children}
        </Container>
      </Collapse>
    </div>
  )
}

const Passport = () => {
  const { t } = useTranslation();
  const { store } = useContext(StoreContext);
  const [currentBike, setCurrentBike] = useState();
  const [lightboxIsOpen, setLightbox] = useState(false);
  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOwner, setIsOwner] = useState(false);


  const { bikePage } = useParams();


  const bikeProperties = [
    { translation: t("frame_serial_number"), property: "serialNumber" },
    { translation: t("bike_type"), property: "bikeType" },
    { translation: t("bike_manufacturer"), property: "manufacturer" },
    { translation: t("bike_model"), property: "model" },
    { translation: t("bike_year"), property: "year" },
    { translation: t("bike_color"), property: "primaryColors" },
    { translation: t("frame_size"), property: "frameSize" },
    { translation: t("frame_material"), property: "frameMaterial" },
    { translation: t("wheels_size"), property: "wheelsSize" },
    { translation: t("country"), property: "country" },
    { translation: t("city"), property: "city" }
  ]


  useEffect(() => {

    const fetchBike = async () => {
      const res = (!store.isAuth ? await BikeService.getBikeByLink(bikePage) : await BikeService.getBikeByLink4Auth(bikePage))
      setCurrentBike(res.data);
      if (res.data.id) {setIsOwner(true)} else setIsOwner(false);
      let imagesData = [];
      for (const [photoType, photoArr] of Object.entries(res.data.photos)) {
        for (let index = 0; index < photoArr.length; ++index)
          imagesData.push({ src: "/photos/" + photoArr[index], photoType: photoType, ind: index });
      }

      setImages(imagesData);
    }

    fetchBike();

  }, [])

  if (!currentBike) return null;

  return (
    <Container className="col-md-8">
      <Lightbox
        open={lightboxIsOpen}
        close={() => setLightbox(false)}
        slides={images}
        index={photoIndex}
        plugins={[Zoom]}
      />
      <h1>{currentBike.manufacturer + " " + currentBike.model + " "}{currentBike.year && currentBike.year}</h1>
      <Container >
        <Row>
          {images.map((image, i, arr) => {
            const arrLen = arr.length;
            const photoCard = <Card>
              <Card.Img
                onClick={() => {
                  setLightbox(true);
                  setPhotoIndex(i);
                }}
                variant="top"
                src={image.src} />
              <Card.Body className="text-end" style={{ position: 'absolute', top: 0, right: 0, padding: '1rem' }}>
                {(image.photoType !== 'bike') &&
                  <span title={t("private")} className="text-warning"><BsEyeSlash /></span>}
                {(i === 0 && isOwner && store.isAuth) && <Button
                  as={Link}
                  to={"/my/bikes/" + currentBike.id + "/edit/photos"}
                  size="sm"
                  variant="secondary">
                  <BsPencilSquare />
                </Button>
                }
              </Card.Body>
            </Card>
            if (i === 0) return <Col key={image.src} sm={12} className="px-0">{photoCard}</Col>
            const modRes = (arrLen - 1) % 4;

            if (modRes === 3 && i < 1 + modRes) return <Col key={image.src} sm={4} className="px-0">{photoCard}</Col>
            if (modRes === 2 && i <= (1 + modRes) * 2 && arrLen < 4) return <Col key={image.src} sm={6} className="px-0">{photoCard}</Col>
            if (modRes === 2 && i <= (1 + modRes) * 2) return <Col key={image.src} sm={4} className="px-0">{photoCard}</Col>
            if (modRes === 1 && i <= 1 + 1) return <Col key={image.src} sm={6} className="px-0">{photoCard}</Col>
            if (modRes === 1 && i <= 1 + 4) return <Col key={image.src} sm={4} className="px-0">{photoCard}</Col>
            return <Col key={image.src} sm={3} className="px-0">{photoCard}</Col>
          })

          }
        </Row>
      </Container>
      <br />
      <h4>
        {t("bike_description")}
        {isOwner && store.isAuth &&
          <Button
            as={Link}
            to={"/my/bikes/" + currentBike.id + "/edit/details"}
            className="ms-1"
            size="sm"
            variant="secondary">
            <BsPencilSquare />
          </Button>
        }
      </h4>
      <ul className="list-unstyled d-md-flex flex-md-wrap">
        {bikeProperties.map((item, i) => {
          if (item.property === 'primaryColors' && currentBike[item.property])
            return (
              <li key={item.property} className="col-md-6">
                <b>{item.translation + ": "}</b>
                {currentBike[item.property].map((color, ii) => <Placeholder key={ii} xs={1} style={{ backgroundColor: color, cursor: 'inherit' }} />)}
              </li>

            )
          if (currentBike[item.property])
            return (<li key={item.property} className="col-md-6"><b>{item.translation + ": "}</b>{currentBike[item.property]}</li>)
        })}
      </ul>

      {currentBike.description &&
        <p> {currentBike.description}</p>
      }
      {currentBike.specs.length > 0 &&
        <CollapseItem title={t("bike_specs")} isOwner={isOwner} bikeId={currentBike.id}>
          <div id="example-collapse-text">
            {currentBike.specs.map((spec) => {
              return <Row key={spec.bikeSection} className="my-3 pt-3 border-top">
                <Col md={3}>
                  <h5>{t(spec.bikeSection)}</h5>
                </Col>
                <Col>
                  {spec.parts.map((item, ind) => {
                    return <Row key={item.id}>
                      <Col className="lh-sm">
                        <b className="fw-normal">{t(item.type)}</b><br />
                        <p className="fw-light">
                          {item.manufacturer + " " + item.model}{item.year && " " + item.year}{" " + item.description}
                        </p>
                      </Col>
                    </Row>
                  })}
                </Col>
              </Row>
            })}
          </ div>
        </CollapseItem>
      }



    </Container>
  );
}

export default observer(Passport);